<template>
  <div>
    <GridHelper v-if="config.currentEnv === 'dev'" />
    <Menu v-if="defaultStore.mainMenu" :menu="defaultStore.mainMenu" />

    <DefaultSection class="is-home">
      <Container>
        <div class="tabs is-fullwidth is-primary is-size-6">
          <ul>
            <li>
              <nuxt-link :to="{ name: 'user-login' }" exact-active-class="is-active">
                <RegisterIcon class="is-hidden-mobile" />
                <h2 class="has-text-weight-semibold">
                  Login
                </h2>
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="{ name: 'user-register' }" exact-active-class="is-active">
                <LoginIcon class="is-hidden-mobile" />
                <h2 class="has-text-weight-semibold">
                  Registrieren
                </h2>
              </nuxt-link>
            </li>
          </ul>
        </div>

        <slot />
      </Container>
    </DefaultSection>
    <FooterContent />
  </div>
</template>

<script setup lang="ts">
import RegisterIcon from '~/assets/svgs/icons/ic24-edit.svg?component'
import LoginIcon from '~/assets/svgs/icons/ic24-user.svg?component'
import { useDefaultStore } from '~/stores/defaultStore'

const defaultStore = useDefaultStore()
const config = useRuntimeConfig()

useHead({
  bodyAttrs: {
    class: 'has-navbar-fixed-top',
  },
})
</script>

<style scoped lang="scss">
.section.is-home {
  padding-bottom: toRem(60);

  @include tablet {
    padding-bottom: toRem(160);
  }
}

.tabs {
  margin-bottom: toRem(60) !important;

  @include tablet {
    margin-bottom: toRem(100) !important;
  }

  ul {
    border: none;
    display: flex;
    flex-wrap: wrap;

    a {
      padding-left: 0;
      padding-right: 0;

      svg {
        margin-right: 1rem;

        :deep(path) {
          fill: $primary;
        }
      }

      &:hover,
      &.is-active {
        svg {
          :deep(path){
            fill: $sky-blue;
          }
        }

        h2 {
          color: $sky-blue;
        }
      }

      &.is-active {
        border-bottom: 4px solid $sky-blue;
      }
    }
  }

  &.is-fullwidth {
    li {
      flex-grow: unset;
      flex-shrink: unset;
      width: calc(33% + 2rem - 15px);
      padding-right: 2rem;
    }
  }
}
</style>
